import styled from 'styled-components';
import { animations, media, sizing, utils } from 'design-system/style';

export const Root = styled.section``;

export const Header = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.space.lg};
`;

const ArrowWrapper = styled.div`
  position: absolute;
  top: 50%;
  transition: opacity ${animations.transition.NORMAL}ms ease-in-out;
  transform: translateY(-50%);
  z-index: 1;

  > button {
    margin: 0;
  }
`;

export const ListWrapper = styled.div`
  position: relative;

  > ${ArrowWrapper} {
    opacity: 0;
  }

  &:hover {
    > ${ArrowWrapper} {
      opacity: 1;
    }
  }
`;

export const List = styled.ul`
  align-items: stretch;
  display: flex;
  list-style: none;
  overflow-x: scroll;
  padding: 0;
  ${utils.hideScrollbars}
  scroll-snap-type: x proximity;
  scroll-margin: ${({ theme }) => theme.space.md};
  scroll-behavior: smooth;
`;

export const ListItem = styled.li`
  align-items: stretch;
  display: flex;
  margin-right: ${sizing(8)};
  scroll-snap-align: center;

  &:last-child {
    margin-right: 0;
  }

  @media screen and (min-width: ${media.queries.TABLET}) {
    margin-right: ${sizing(16)};
  }
`;

export const VideoPlayerCarouselButtons = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
`;

export const ReviewCardContainer = styled.div`
  background: #201e23;
  border-radius: ${sizing(16)};
  width: 308px;
  height: 166px;
  padding: 20px;
`;

export const Title = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;

  color: #f2f1f3;
  margin-bottom: 2px;
`;

export const RatingAndDateContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
`;

export const DateText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;

  margin-left: 2px;

  color: #bfbac4;
  align-self: center;
`;

export const CusotmerFirstName = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;

  color: #bfbac4;

  align-self: center;
  margin-left: 8px;
`;

export const ReviewText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: #bfbac4;

  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;

  overflow: hidden;
  text-overflow: ellipsis;
  font-style: italic;
`;
