import React from 'react';
import { Rating } from 'components/Rating';

import {
  ReviewCardContainer,
  Title,
  RatingAndDateContainer,
  CusotmerFirstName,
  ReviewText,
} from './Styled';

export type ReviewCardProps = {
  author: string;
  body: string;
  talentName: string;
  occasion?: string;
  rating: number;
};

const ReviewCard = ({
  author,
  body,
  talentName,
  occasion,
  rating,
}: ReviewCardProps) => {
  return (
    <ReviewCardContainer>
      <Title>{`${occasion} from ${talentName}`}</Title>
      <RatingAndDateContainer>
        <Rating rating={rating} />
        <CusotmerFirstName>{author}</CusotmerFirstName>
      </RatingAndDateContainer>
      <ReviewText>{body}</ReviewText>
    </ReviewCardContainer>
  );
};

export default ReviewCard;
