import { resizeImage } from 'utils/getResizedImageUrl';

export const CAMEO_KIDS_CATEGORY_CIRCLE = {
  categoryName: 'Kids',
  imageUrl: resizeImage(300, 300, 'cC8wpB-RI_avatar-1700006545214.jpg'),
  path: '/kids',
};

export const TOP_HEADER_CATEGORIES = [
  {
    categoryName: 'Actors',
    imageUrl: resizeImage(300, 300, 'SxUBL3q7Zs_avatar-1696280652629.jpg'),
    path: '/browse/actors',
  },
  {
    categoryName: 'Reality TV',
    imageUrl: resizeImage(300, 300, 'yoEpxaRmK_image01.jpeg'),
    path: '/browse/reality-tv',
  },
  {
    categoryName: 'Athletes',
    imageUrl: resizeImage(
      300,
      300,
      'LATSKELYX_Ct85nmgl8-F26EC9C8-057D-4D40-90A6-9A6A15D118F7.jpg'
    ),
    path: '/browse/athletes',
  },
  {
    categoryName: 'Comedians',
    imageUrl: resizeImage(
      300,
      300,
      '-NIe1Gtj-A7C4327C-5982-42DD-9163-27403DFFAAB1.jpg'
    ),
    path: '/browse/comedians',
  },
  {
    categoryName: 'Musicians',
    imageUrl: resizeImage(
      300,
      300,
      'h_lFq08bN_BuY8gTjly_05672F00-E67C-42A1-AA0A-955366EE99CC.jpeg'
    ),
    path: '/browse/musicians',
  },
  {
    categoryName: 'Fantasy Football',
    imageUrl: resizeImage(300, 300, 'oPqRPF8_N_avatar-iBtyUX2uD.jpg'),
    path: '/fantasy-football',
  },
  {
    categoryName: 'For business',
    imageUrl: resizeImage(300, 300, 'naujpNavL_9BW9tfMCH.png'),
    path: '/tags/business',
    desktopOnly: true,
  },
];

export const TOP_HEADER_CATEGORIES_UK = [
  {
    categoryName: 'Footballers',
    imageUrl: resizeImage(300, 300, '9QvE0BXAY_avatar-1709159564706.jpg'),
    path: '/tags/footballers',
  },
  {
    categoryName: 'Harry Potter',
    imageUrl: resizeImage(300, 300, '4nEUBFeVG_avatar--HPX4iUtK.jpg'),
    path: '/browse/actors/movies/harry-potter',
  },
  {
    categoryName: 'Actors',
    imageUrl: resizeImage(300, 300, 'qYRZMSRup_avatar-giL13V9L.jpg'),
    path: '/browse/actors',
  },
  {
    categoryName: 'Reality TV',
    imageUrl: resizeImage(300, 300, '7417iM8DR_avatar-1708632570132.jpg'),
    path: '/browse/reality-tv',
  },
  {
    categoryName: 'Comedians',
    imageUrl: resizeImage(300, 300, 'XcG-ERLe1_avatar-1698135471118.jpg'),
    path: '/browse/comedians',
  },
  {
    categoryName: 'Musicians',
    imageUrl: resizeImage(300, 300, 'DwHWJ5MO1_avatar-i7yWEmIEg.jpg'),
    path: '/browse/musicians',
  },
  {
    categoryName: 'For business',
    imageUrl: resizeImage(300, 300, 'naujpNavL_9BW9tfMCH.png'),
    path: '/tags/business',
    desktopOnly: true,
  },
];

export const BOTTOM_CATEGORIES = [
  {
    categoryName: 'Actors',
    imageUrl: resizeImage(300, 300, 'vd0-X50Cr_avatar-Q5zpD9Ef2.jpg'),
    path: '/browse/actors',
  },
  {
    categoryName: 'Reality TV',
    imageUrl: resizeImage(300, 300, '7ce15144-ec04-455c-bf15-a7eba1b7be78.png'),
    path: '/browse/reality-tv',
  },
  {
    categoryName: 'Athletes',
    imageUrl: resizeImage(300, 300, '9G3US22An_avatar-yfQdQ2etd.jpg'),
    path: '/browse/athletes',
  },
  {
    categoryName: 'Comedians',
    imageUrl: resizeImage(300, 300, 'da2432b4-ac5d-4493-8c0f-bd35263fb278.png'),
    path: '/browse/comedians',
  },
  {
    categoryName: 'Creators',
    imageUrl: resizeImage(300, 300, 'e3CtiPCCG_avatar-1671443157826.jpg'),
    path: '/browse/creators',
  },
  {
    categoryName: 'Musicians',
    imageUrl: resizeImage(
      300,
      300,
      'qIKOXUVb7-42C1938C-9B0B-4EFB-B122-B5576BAB4364.jpg'
    ),
    path: '/browse/musicians',
  },
  {
    categoryName: 'For business',
    imageUrl: resizeImage(300, 300, 'naujpNavL_9BW9tfMCH.png'),
    path: '/tags/business',
    desktopOnly: true,
  },
];

export const PRICE_BUCKET = {
  type: 'carousel',
  items: [
    {
      backgroundImage: {
        type: 'image',
        imageUrlKey: 'b7e35f1b-39ea-4f93-a6bb-ef94d7e6bb5f.png',
        altText: '',
      },
      caption: '',
      captionPosition: 'below',
      captionColor: '#000000',
      cta: {
        linkType: 'tag',
        text: '',
        linkKey: '25-or-less',
      },
    },
    {
      backgroundImage: {
        type: 'image',
        imageUrlKey: '7a8d5c01-2e4b-4a07-b50d-fe65aa48d0c5.png',
        altText: '',
      },
      caption: '',
      captionPosition: 'below',
      captionColor: '#000000',
      cta: {
        linkType: 'tag',
        text: '',
        linkKey: '50-or-less',
      },
    },
    {
      backgroundImage: {
        type: 'image',
        imageUrlKey: '0a034bf8-9423-44b9-9ff3-607a5adf649e.png',
        altText: '',
      },
      caption: '',
      captionPosition: 'below',
      captionColor: '#000000',
      cta: {
        linkType: 'tag',
        text: '',
        linkKey: '100-or-less',
      },
    },
    {
      backgroundImage: {
        type: 'image',
        imageUrlKey: 'eb2eebb7-850a-4dfe-9660-9eb7572c9cf3.png',
        altText: '',
      },
      caption: '',
      captionPosition: 'below',
      captionColor: '#000000',
      cta: {
        linkType: 'tag',
        text: '',
        linkKey: '150-or-less',
      },
    },
  ],
  rowTitle: { title: 'Gifts for every budget' },
  isStackable: false,
  key: 'homepage-redesign-price-buckets',
};

export const INSPO_CAROUSEL = {
  type: 'carousel',
  items: [
    {
      backgroundImage: {
        type: 'image',
        imageUrlKey: '129477bd-fef3-4b2b-bfad-df5489b06152.jpg',
        altText: '',
      },
      caption: '',
      captionPosition: 'below',
      captionColor: '#000000',
      cta: {
        linkType: 'tag',
        text: '',
        linkKey: 'new-and-trending',
      },
    },
    {
      backgroundImage: {
        type: 'image',
        imageUrlKey: 'd8392c8d-b975-4c8a-aa95-d6ee0cf46fe4.jpg',
        altText: '',
      },
      caption: '',
      captionPosition: 'below',
      captionColor: '#000000',
      cta: {
        linkType: 'tag',
        text: '',
        linkKey: 'dadsidols',
      },
    },
    {
      backgroundImage: {
        type: 'image',
        imageUrlKey: '22ce7f69-21cd-40b1-94fe-16d13a8fa68c.jpg',
        altText: '',
      },
      caption: '',
      captionPosition: 'below',
      captionColor: '#000000',
      cta: {
        linkType: 'tag',
        text: '',
        linkKey: 'heart-throbs',
      },
    },
    {
      backgroundImage: {
        type: 'image',
        imageUrlKey: 'f6742b85-ba25-4304-82df-f9227087228d.jpg',
        altText: '',
      },
      caption: '',
      captionPosition: 'below',
      captionColor: '#000000',
      cta: {
        linkType: 'tag',
        text: '',
        linkKey: 'best-sellers',
      },
    },
  ],
  rowTitle: { title: 'Featured picks' },
  isStackable: false,
  key: 'homepage-redesign-featured-picks',
};

export const INSPO_CAROUSEL_UK = {
  type: 'carousel',
  items: [
    {
      backgroundImage: {
        type: 'image',
        imageUrlKey: '06b13334-499c-405a-b606-503b6ec5ac5b.jpg',
        altText: 'Best Sellers',
      },
      caption: '',
      captionPosition: 'below',
      captionColor: '#000000',
      cta: {
        linkType: 'tag',
        text: '',
        linkKey: 'uk-best-sellers',
      },
    },
    {
      backgroundImage: {
        type: 'image',
        imageUrlKey: '17acf3f1-cf48-4e74-a9b0-f78c0bcd3478.jpg',
        altText: 'Athletes',
      },
      caption: '',
      captionPosition: 'below',
      captionColor: '#000000',
      cta: {
        linkType: 'category',
        text: '',
        linkKey: 'athletes',
      },
    },
    {
      backgroundImage: {
        type: 'image',
        imageUrlKey: '6e43c27f-4cff-4bfe-b92f-65aa3417529d.jpg',
        altText: 'Footballers',
      },
      caption: '',
      captionPosition: 'below',
      captionColor: '#000000',
      cta: {
        linkType: 'tag',
        text: '',
        linkKey: 'footballers',
      },
    },
    {
      backgroundImage: {
        type: 'image',
        imageUrlKey: '57ca443f-9b9e-439a-82e8-f385d34ce57f.jpg',
        altText: 'New & Trending',
      },
      caption: '',
      captionPosition: 'below',
      captionColor: '#000000',
      cta: {
        linkType: 'tag',
        text: '',
        linkKey: 'new-and-trending',
      },
    },
  ],
  rowTitle: { title: 'Featured picks' },
  isStackable: false,
  key: 'homepage-redesign-featured-picks',
};

export const VALENTINES_INSPO_CAROUSEL = {
  type: 'carousel',
  items: [
    {
      backgroundImage: {
        type: 'image',
        imageUrlKey: '6f3984d9-1a59-44a9-a773-acdb55bdb5c4.jpg',
        altText: '',
      },
      caption: '',
      captionPosition: 'below',
      captionColor: '#000000',
      cta: {
        linkType: 'tag',
        text: '',
        linkKey: 'best-sellers',
      },
    },
    {
      backgroundImage: {
        type: 'image',
        imageUrlKey: '48e29484-164e-429f-918d-173773147ce5.jpg',
        altText: '',
      },
      caption: '',
      captionPosition: 'below',
      captionColor: '#000000',
      cta: {
        linkType: 'tag',
        text: '',
        linkKey: 'dating-show-favorites',
      },
    },
    {
      backgroundImage: {
        type: 'image',
        imageUrlKey: '19ce8431-2398-49fc-9145-2d4048e05897.jpg',
        altText: '',
      },
      caption: '',
      captionPosition: 'below',
      captionColor: '#000000',
      cta: {
        linkType: 'tag',
        text: '',
        linkKey: 'heart-throbs',
      },
    },
    {
      backgroundImage: {
        type: 'image',
        imageUrlKey: 'c2f20f2f-1fee-4f4d-b05a-19654bb7c287.jpg',
        altText: '',
      },
      caption: '',
      captionPosition: 'below',
      captionColor: '#000000',
      cta: {
        linkType: 'tag',
        text: '',
        linkKey: 'new-and-trending',
      },
    },
  ],
  rowTitle: { title: 'Featured picks' },
  isStackable: false,
  key: 'homepage-redesign-featured-picks',
};

export const FATHERS_DAY_INSPO_CAROUSEL = {
  type: 'carousel',
  items: [
    {
      backgroundImage: {
        type: 'image',
        imageUrlKey: '961de347-61d3-4c51-ac04-ea4b7d3109f5.jpg',
        altText: '',
      },
      caption: '',
      captionPosition: 'below',
      captionColor: '#000000',
      cta: {
        linkType: 'tag',
        text: '',
        linkKey: 'best-sellers',
      },
    },
    {
      backgroundImage: {
        type: 'image',
        imageUrlKey: '2ac82f4d-4dbf-4100-a69b-9b8c8c9e2887.jpg',
        altText: '',
      },
      caption: '',
      captionPosition: 'below',
      captionColor: '#000000',
      cta: {
        linkType: 'tag',
        text: '',
        linkKey: 'dadsidols',
      },
    },
    {
      backgroundImage: {
        type: 'image',
        imageUrlKey: '611fb379-0a10-4ece-bcd7-69f7f35692db.jpg',
        altText: '',
      },
      caption: '',
      captionPosition: 'below',
      captionColor: '#000000',
      cta: {
        linkType: 'tag',
        text: '',
        linkKey: 'make-dad-laugh',
      },
    },
    {
      backgroundImage: {
        type: 'image',
        imageUrlKey: 'a4383a9c-a426-4449-a09f-4df13d60f1f4.jpg',
        altText: '',
      },
      caption: '',
      captionPosition: 'below',
      captionColor: '#000000',
      cta: {
        linkType: 'tag',
        text: '',
        linkKey: 'new-and-trending',
      },
    },
  ],
  rowTitle: { title: 'Featured picks' },
  isStackable: false,
  key: 'homepage-redesign-featured-picks',
};

export const SUMMER_INSPO_CAROUSEL = {
  type: 'carousel',
  items: [
    {
      backgroundImage: {
        type: 'image',
        imageUrlKey: '79a0248d-9351-4610-bbd5-ce75827aa1c4.jpg',
        altText: '',
      },
      caption: '',
      captionPosition: 'below',
      captionColor: '#000000',
      cta: {
        linkType: 'tag',
        text: '',
        linkKey: 'best-sellers',
      },
    },
    {
      backgroundImage: {
        type: 'image',
        imageUrlKey: '0581bb92-c5da-4914-b583-9aed3e9996d5.jpg',
        altText: '',
      },
      caption: '',
      captionPosition: 'below',
      captionColor: '#000000',
      cta: {
        linkType: 'site',
        text: '',
        linkKey: 'fantasy-football',
      },
    },
    {
      backgroundImage: {
        type: 'image',
        imageUrlKey: '99b75a5c-6afa-4c05-875b-3f22d45ded8e.jpg',
        altText: '',
      },
      caption: '',
      captionPosition: 'below',
      captionColor: '#000000',
      cta: {
        linkType: 'tag',
        text: '',
        linkKey: 'wedding-season',
      },
    },
    {
      backgroundImage: {
        type: 'image',
        imageUrlKey: 'd8fac3a6-19b0-48be-8a85-7c6ee5a17536.jpg',
        altText: '',
      },
      caption: '',
      captionPosition: 'below',
      captionColor: '#000000',
      cta: {
        linkType: 'tag',
        text: '',
        linkKey: 'new-and-trending',
      },
    },
  ],
  rowTitle: { title: 'Featured picks' },
  isStackable: false,
  key: 'homepage-redesign-featured-picks',
};

export const FANTASTY_INSPO_CAROUSEL = {
  type: 'carousel',
  items: [
    {
      backgroundImage: {
        type: 'image',
        imageUrlKey: '17389e08-0c9e-458a-a2fb-5e32b2ff6230.jpg',
        altText: '',
      },
      caption: '',
      captionPosition: 'below',
      captionColor: '#000000',
      cta: {
        linkType: 'site',
        text: '',
        linkKey: 'fantasy-football',
      },
    },
    {
      backgroundImage: {
        type: 'image',
        imageUrlKey: '95173e1c-ff61-49ac-9302-3702b4136e95.jpg',
        altText: '',
      },
      caption: '',
      captionPosition: 'below',
      captionColor: '#000000',
      cta: {
        linkType: 'tag',
        text: '',
        linkKey: 'best-sellers',
      },
    },
    {
      backgroundImage: {
        type: 'image',
        imageUrlKey: '0891f0a5-b0fb-406b-a540-da5f7aab4194.jpg',
        altText: '',
      },
      caption: '',
      captionPosition: 'below',
      captionColor: '#000000',
      cta: {
        linkType: 'site',
        text: '',
        linkKey: 'harry-potter',
      },
    },
    {
      backgroundImage: {
        type: 'image',
        imageUrlKey: '3653f423-a1d4-4de8-bb72-b952d33b8d0a.jpg',
        altText: '',
      },
      caption: '',
      captionPosition: 'below',
      captionColor: '#000000',
      cta: {
        linkType: 'tag',
        text: '',
        linkKey: 'new-and-trending',
      },
    },
  ],
  rowTitle: { title: 'Featured picks' },
  isStackable: false,
  key: 'homepage-redesign-featured-picks',
};
