import CarouselRow from 'components/BrowseRow/Merchandising/CarouselRow';
import React from 'react';
import styled from 'styled-components';
import { sizing } from 'design-system/style';
import { H2styles } from './Styled';

type Props = {
  rowIndex: number;
  source: any;
};

const CarouselRowSection = ({ rowIndex, source }: Props) => {
  return (
    <Wrapper>
      <CarouselRow
        source={source as any}
        key={`carousel-${source.key}`}
        rowIndex={rowIndex}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  div h2 {
    ${H2styles}
    margin-bottom: ${sizing(16)};
  }
`;

export default CarouselRowSection;
