import React from 'react';
import { Edit2, Mail, Search, Share } from 'react-feather';
import getHomepageAnalytics from '../getHomepageAnalytics';
import {
  HowItWorksSectionContainer,
  HowItWorksSectionItem,
  HowItWorksSectionItems,
  HowItWorksSectionItemDescription,
  HowItWorksSectionItemIcon,
  HowItWorksSectionItemTitle,
  HowItWorksSectionSubtitle,
  HowItWorksSectionSubtitleLink,
  HowItWorksSectionSubtitleText,
  HowItWorksSectionTitle,
} from './Styled';

const steps = [
  {
    title: 'Find a celebrity',
    description: 'Browse thousands of stars offering personalized videos.',
    icon: <Search color="#F2F1F3" />,
  },
  {
    title: 'Tell them what to say',
    description:
      'During checkout, you’ll provide the details the celeb will need to make the perfect personalized video.',
    icon: <Edit2 color="#F2F1F3" />,
  },
  {
    title: 'Get your video',
    description:
      'Celebs have up to 7 days to complete your request. When it’s ready, we’ll send it directly to you.',
    icon: <Mail color="#F2F1F3" />,
  },
  {
    title: 'Share with loved ones',
    description:
      'Send the video to friends and family and don’t forget to capture their priceless reactions.',
    icon: <Share color="#F2F1F3" />,
  },
];

type Props = {
  homepageAnalytics: ReturnType<typeof getHomepageAnalytics>;
  hideLearnMore?: boolean;
  customHeader?: string;
};

const HowItWorksSection = ({
  homepageAnalytics,
  hideLearnMore,
  customHeader = 'How Cameo works',
}: Props) => {
  const onClick = () => {
    homepageAnalytics.trackLearnMoreClick();
    window.open('/about', '_blank');
  };
  return (
    <HowItWorksSectionContainer>
      <HowItWorksSectionTitle>{customHeader}</HowItWorksSectionTitle>
      <HowItWorksSectionSubtitle>
        <HowItWorksSectionSubtitleText>
          {'Get a personalized video in just four steps. '}
        </HowItWorksSectionSubtitleText>
        {!hideLearnMore && (
          <HowItWorksSectionSubtitleLink onClick={onClick}>
            {'Learn more'}
          </HowItWorksSectionSubtitleLink>
        )}
      </HowItWorksSectionSubtitle>
      <HowItWorksSectionItems>
        {steps.map((step) => (
          <HowItWorksSectionItem key={step.title}>
            <HowItWorksSectionItemIcon>{step.icon}</HowItWorksSectionItemIcon>
            <HowItWorksSectionItemTitle>
              {step.title}
            </HowItWorksSectionItemTitle>
            <HowItWorksSectionItemDescription>
              {step.description}
            </HowItWorksSectionItemDescription>
          </HowItWorksSectionItem>
        ))}
      </HowItWorksSectionItems>
    </HowItWorksSectionContainer>
  );
};

export default HowItWorksSection;
