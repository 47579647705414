import styled from 'styled-components';
import { fonts, media, sizing } from 'design-system/style';
import { CarouselRowContainer } from 'components/BrowseRow/Merchandising/CarouselRow/Styled';
import { CoverPhoto } from 'components/BrowseRow/Merchandising/CarouselRow/CarouselItem/Styled';
import { UserStackedScrollRow } from 'components/BrowseRow/CollectionView/Styled';
import { UserStackItem } from 'components/BrowseRow/CollectionView/renderers/UserStack/Styled';
import { Header } from 'components/ReviewsRow/Styled';
import { BROWSE_GUTTER_LARGE } from 'components/PageContentWrapper/Styled';
import { sizes } from 'domains/web/theme/sizes';

// TODO: assuming SectionWrapper may have some tighter margins than profile page?

/**
 * @deprecated use token from theme when possible
 */
const PROFILE_LAYOUT_MAX_WIDTH = sizes.content;

export const Root = styled.div`
  ${fonts.regularText['200']}
  margin: auto;
  max-width: ${PROFILE_LAYOUT_MAX_WIDTH};
  margin-top: ${sizing(32)};
  padding-bottom: ${sizing(64)};
  width: 100%;

  ${CarouselRowContainer} {
    gap: ${sizing(8)};

    @media screen and (min-width: ${media.queries.TABLET}) {
      gap: ${sizing(16)};
    }
  }

  ${CoverPhoto} {
    border-radius: ${sizing(16)};
  }

  ${UserStackedScrollRow} > div {
    ${UserStackItem} {
      margin-left: 0;
      margin-right: 0;

      @media screen and (min-width: ${media.queries.TABLET}) {
        margin-left: ${sizing(16)};
        margin-right: 0;
      }
    }

    :first-child {
      @media screen and (min-width: ${media.queries.TABLET}) {
        ${UserStackItem} {
          margin-left: 0;
        }
      }
    }

    &:nth-child(n + 5) {
      display: none;

      @media screen and (min-width: ${media.queries.TABLET}) {
        display: block;
      }
    }
  }

  ${UserStackItem} {
    @media screen and (min-width: ${media.queries.TABLET}) {
      width: ${sizing(200)};
      max-width: auto;
      min-width: auto;
    }
  }
`;

export const Wrapper = styled.div`
  @media screen and (min-width: ${media.queries.TABLET}) {
    padding: 0 ${BROWSE_GUTTER_LARGE};
  }

  @media screen and (min-width: 1280px) {
    margin: auto;
  }

  @media screen and (min-width: 1370px) {
    padding: 0;
  }
`;

export const SectionWrapperContainer = styled.div<{
  isHorizontallyScrollable?: boolean;
  noDivider?: boolean;
}>`
  ${({ noDivider }) => !noDivider && `border-bottom: 12px solid #000000;`}

  & + & {
    margin-top: ${sizing(64)};
  }

  padding: ${({ isHorizontallyScrollable }) =>
    isHorizontallyScrollable ? `0 0 0 ${sizing(20)}` : `0 ${sizing(20)}`};

  @media screen and (min-width: ${media.queries.TABLET}) {
    padding: 0;
    ${({ noDivider }) =>
      !noDivider && `border-bottom: 1px solid rgb(63, 59, 69);`}
  }

  ${({ isHorizontallyScrollable }) =>
    isHorizontallyScrollable
      ? `
      ${Header} {
        padding-right: ${sizing(20)};
      }
    `
      : ''}
`;

SectionWrapperContainer.defaultProps = {
  noDivider: false,
};

const FOREGROUND_DEFAULT = '#f2f1f3';
const MUTED = '#bfbac4';

export const H2styles = `
  margin: 0px;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: ${FOREGROUND_DEFAULT};
`;

// TODO: Clean this up, I'm doing this because all browse rows use a different H2 from design system that has slightly different specs than in the design.
export const H2HackWrapper = styled.div`
  div h2 {
    ${H2styles}
  }
`;

// copied from figma lets try this and then ill port to theme
export const H2 = styled.h2`
  ${H2styles}
`;

export const H3 = styled.h3`
  margin: 0px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.1px;

  color: ${FOREGROUND_DEFAULT};
`;

export const Caption = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.1px;
  margin: 0px;

  color: ${MUTED};
`;

export const ThisIsCameoWrapper = styled.div`
  display: inline-flex;
  gap: 24px;
  justify-content: space-between;
  flex-direction: column;

  @media screen and (min-width: ${media.queries.TABLET}) {
    flex-direction: row;
  }
`;

export const SectionTitle = styled(H2)`
  margin-bottom: ${sizing(16)};
`;
