import React, { ReactNode } from 'react';
import { useOnImpression } from 'utils/hooks';
import getHomepageAnalytics from './getHomepageAnalytics';
import { SectionWrapperContainer } from './Styled';

type Props = {
  children: ReactNode;
  homepageAnalytics: ReturnType<typeof getHomepageAnalytics>;
  index: number;
  isHorizontallyScrollable?: boolean;
  noDivider?: boolean;
  rowKey: string;
};

const SectionWrapper = ({
  children,
  homepageAnalytics,
  index,
  isHorizontallyScrollable,
  noDivider,
  rowKey,
}: Props) => {
  const onImpression = () => {
    homepageAnalytics.trackSectionInView({ index, rowKey });
  };

  const { observedElementRef } = useOnImpression(onImpression, {
    threshold: 0.25,
  });

  return (
    <SectionWrapperContainer
      isHorizontallyScrollable={isHorizontallyScrollable}
      noDivider={noDivider}
      ref={observedElementRef}
    >
      {children}
    </SectionWrapperContainer>
  );
};

export default SectionWrapper;
