import styled from 'styled-components';
import { animations, utils } from 'design-system/style';

export const Root = styled.section``;

export const Header = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.space.sm}px;
`;

const ArrowWrapper = styled.div`
  position: absolute;
  top: 50%;
  transition: opacity ${animations.transition.NORMAL}ms ease-in-out;
  transform: translateY(-50%);
  z-index: 1;

  > button {
    margin: 0;
  }
`;

export const ArrowLeftWrapper = styled(ArrowWrapper)`
  left: 8px;
`;

export const ArrowRightWrapper = styled(ArrowWrapper)`
  right: 8px;
`;

export const ListWrapper = styled.div`
  display: inline-grid;
  position: relative;

  > ${ArrowWrapper} {
    opacity: 0;
  }

  &:hover {
    > ${ArrowWrapper} {
      opacity: 1;
    }
  }
`;

export const List = styled.ul`
  align-items: stretch;
  display: flex;
  list-style: none;
  margin: 0;
  overflow-x: scroll;
  padding: 0;
  gap: 1.6rem;
  ${utils.hideScrollbars}
`;

export const ListItem = styled.li`
  align-items: stretch;
  display: flex;
  margin-right: ${({ theme }) => theme.space.sm}px;

  &:last-child {
    margin-right: 0;
  }
`;
