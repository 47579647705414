import { useRef, useEffect } from 'react';
import { useInViewport } from 'react-in-viewport';

/**
 * Hook to trigger an action on component impression.
 *
 * Will call the provided function the first time the Observed element
 * comes into the viewport.
 *
 * Can be used to easily track component impression event
 * @example   const { observedElementRef } = useOnImpression(() => analytics.trackSystem({}));
 */
export const useOnImpression = (
  callback: () => void,
  options: IntersectionObserverInit = {},
  props: any = {}
) => {
  const observedElementRef = useRef(null);
  const { inViewport, enterCount } = useInViewport(
    observedElementRef,
    options,
    { disconnectOnLeave: true },
    props
  );

  useEffect(() => {
    if (enterCount === 1 && inViewport) {
      callback();
    }
  }, [enterCount, inViewport]);

  return { observedElementRef };
};
