import React from 'react';
import VisibilityProvider from 'components/BrowseRow/CollectionView/VisibilityContext';
import WithItemVisibility from 'components/BrowseRow/CollectionView/VisibilityContext/WithItemVisibility';
import {
  ScrollArrowLeft,
  ScrollArrowRight,
} from 'components/BrowseRow/CollectionView/ScrollArrows';
import { useToggle } from 'utils/hooks';
import ReviewCard from './ReviewCard';
import { H2 } from '../../Styled';

import {
  Root,
  Header,
  ListWrapper,
  List,
  ListItem,
  VideoPlayerCarouselButtons,
} from './Styled';

const PLACEHOLDER_DATA = [
  {
    scrubbedTitle:
      'We cannot thank you enough! Sophie was beyond thrilled to get your message and it really helped her get through a little bit of missing home!',
    createdAt: '2022-11-17 20:39:12.584 +0000',
    rating: 5,
    occasion: 'Birthday',
    author: 'Sophie',
    talentName: 'Joe Veleno',
  },
  {
    scrubbedTitle:
      "I surprised my best friend with a pep talk when she was feeling down. Mr Macqueen's message was beyond what either of us expected!",
    createdAt: '2022-11-17 20:32:59.486 +0000',
    rating: 5,
    occasion: 'Advice',
    author: 'Jean',
    talentName: 'Alex Macqueen',
  },
  {
    scrubbedTitle:
      "She's watched it multiple times, shared it with friends and family, and continues to tell everyone about it. Thank you so much for making her day/month/year!",
    createdAt: '2022-11-17 17:48:28.886 +0000',
    rating: 5,
    occasion: 'Advice',
    author: 'Eileen',
    talentName: 'Max Bemis',
  },
  {
    scrubbedTitle:
      'She absolutely loved the video you made for her! Thank you for talking from the heart! She bawled her eyes out and lifted her spirits up so much!',
    createdAt: '2022-11-17 18:41:56.150 +0000',
    rating: 5,
    occasion: 'Motivation',
    author: 'Maddy',
    talentName: 'Ian Somerhalder',
  },
  {
    scrubbedTitle:
      "This brought so much joy to my fiance Jesse! I got this video last year for his bday and can't believe I didn't leave a review then! It's the gift that keeps on giving!",
    createdAt: '2022-11-17 14:19:33.221 +0000',
    rating: 5,
    occasion: 'Birthday',
    author: 'Jesse',
    talentName: 'James Marsters',
  },
  {
    scrubbedTitle:
      'This is the greatest thing I have ever seen in my entire life and I have never been more excited to gift a present to someone as I am this cameo!',
    createdAt: '2022-11-17 20:36:17.547 +0000',
    rating: 5,
    occasion: 'Birthday',
    author: 'Robin',
    talentName: 'Michael Burke',
  },
  {
    scrubbedTitle:
      'Steve exceeded all expectations i had going into this app. The video he left was hilarious and even more lengthy than I thought it was going to be!',
    createdAt: '2022-11-17 20:22:27.737 +0000',
    rating: 5,
    occasion: 'Congratulations',
    author: 'Samantha',
    talentName: 'Steve Bridges',
  },
];

type Props = {
  title?: string;
};

const ReviewsRow = ({ title }: Props) => {
  const isScrollingToggle = useToggle();

  const onScrollArrowClick = () => {
    isScrollingToggle.on();

    setTimeout(isScrollingToggle.off, 500);
  };

  return (
    <>
      <Root>
        <VisibilityProvider
          numItems={PLACEHOLDER_DATA.length}
          analyticsData={{
            rowLabelText: 'ReviewsCarousel',
            rowKey: 'ReviewsCarousel',
            row: 1,
          }}
          scrollOption={{
            behavior: 'smooth',
            block: 'nearest',
            inline: 'start',
          }}
        >
          <Header>
            <H2 color="textDefault">{title || 'Recent reviews'}</H2>
            <VideoPlayerCarouselButtons>
              <ScrollArrowLeft onClick={onScrollArrowClick} addScrollLeft />
              <ScrollArrowRight onClick={onScrollArrowClick} addScrollRight />
            </VideoPlayerCarouselButtons>
          </Header>

          <ListWrapper>
            <List>
              {PLACEHOLDER_DATA.map((review, index) => {
                return (
                  <ListItem key={`review-card-${review.author}-${index}`}>
                    <WithItemVisibility
                      index={index}
                      style={{ display: 'grid' }}
                    >
                      <ReviewCard
                        author={review.author}
                        body={review.scrubbedTitle}
                        rating={review.rating}
                        talentName={review.talentName}
                        occasion={review.occasion}
                      />
                    </WithItemVisibility>
                  </ListItem>
                );
              })}
            </List>
          </ListWrapper>
        </VisibilityProvider>
      </Root>
    </>
  );
};

export default ReviewsRow;
