import { fonts, media, sizing } from 'design-system/style';
import styled from 'styled-components';

export const HowItWorksSectionContainer = styled.div``;

export const HowItWorksSectionTitle = styled.div`
  ${fonts.regularText['200']}
  color: ${({ theme }) => theme.colors.foreground.default};
  font-size: ${sizing(20)};
  font-weight: 700;
`;

export const HowItWorksSectionSubtitle = styled.div`
  ${fonts.regularText['200']}
  margin-top: ${sizing(4)};
  color: ${({ theme }) => theme.colors.foreground.muted};
  font-size: ${sizing(14)};
`;

export const HowItWorksSectionSubtitleText = styled.span``;

export const HowItWorksSectionSubtitleLink = styled.span`
  color: ${({ theme }) => theme.colors.foreground.default};
  font-weight: 500;
  text-decoration: underline;

  &:hover {
    color: ${({ theme }) => theme.colors.foreground.default};
    cursor: pointer;
  }
`;

export const HowItWorksSectionItems = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  margin-top: ${sizing(16)};
  gap: ${sizing(8)};
  overflow-x: scroll;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  scroll-snap-type: x proximity;
  scroll-margin: ${({ theme }) => theme.space.md};
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    width: 0 !important;
    height: 0 !important;
  }

  @media screen and (min-width: ${media.queries.TABLET}) {
    gap: ${sizing(16)};
    overflow-x: hidden;
    width: 100%;
  }
`;

export const HowItWorksSectionItem = styled.div`
  min-width: ${sizing(306)};
  padding: ${sizing(26)} ${sizing(22)};
  background-color: ${({ theme }) => theme.colors.background.level2};
  border-radius: ${sizing(16)};
  overflow: hidden;
  scroll-snap-align: center;
  scroll-margin: ${({ theme }) => theme.space.md};

  @media screen and (min-width: ${media.queries.TABLET}) {
    flex: 1;
    min-width: auto;
  }
`;

export const HowItWorksSectionItemDescription = styled.div`
  ${fonts.regularText['200']}
  margin-top: ${sizing(2)};
  color: ${({ theme }) => theme.colors.foreground.muted};
  font-weight: 400;
  font-size: ${sizing(14)};
`;
export const HowItWorksSectionItemIcon = styled.div``;

export const HowItWorksSectionItemTitle = styled.div`
  ${fonts.regularText['200']}
  margin-top: ${sizing(8)};
  color: ${({ theme }) => theme.colors.foreground.default};
  font-size: ${sizing(16)};
  font-weight: 700;
`;
