import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Text } from 'domains/web/components';
import { selectors as routerSelectors } from 'state/modules/router';
import { CarouselRow as CarouselRowType } from 'types/src/api/endpoints/explore/browseRow';
import useScreenWidth from 'utils/hooks/useScreenWidth';
import ScrollArrows from '../../CollectionView/ScrollArrows';
import VisibilityProvider from '../../CollectionView/VisibilityContext';
import CarouselItem from './CarouselItem';
import { CarouselRowContainer } from './Styled';
import { KIDS_LARGE_DESKTOP_WIDTH } from './constants';

type OwnProps = {
  source: CarouselRowType;
  rowIndex: number;
};

const CarouselRow: React.FunctionComponent<OwnProps> = ({
  source,
  rowIndex,
}: OwnProps) => {
  const isKidsPage = useSelector(routerSelectors.isKidsPage);
  const screenWidth = useScreenWidth();

  const { items, key, isStackable, isScrollable, rowTitle } = source;
  const marginBottom = isKidsPage ? '64px' : undefined;
  const rowTitleText = rowTitle?.title || rowTitle?.shortTitle;
  const showScrollArrows = isScrollable && items.length > 4;

  return (
    <div data-testid="carousel-row">
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        {rowTitleText && (
          <Text as="h2" variant="title_l" fontWeight="bold" mb={2}>
            {rowTitleText}
          </Text>
        )}
        {showScrollArrows && <ScrollArrows />}
      </Box>
      <CarouselRowContainer
        isKidsPage={isKidsPage}
        isStackable={isStackable}
        marginBottom={marginBottom}
      >
        {items.map((item, index) => {
          if (
            isKidsPage &&
            screenWidth < KIDS_LARGE_DESKTOP_WIDTH &&
            index >= 4
          ) {
            return null;
          }
          return (
            <CarouselItem
              colIndex={index}
              item={item}
              key={`carousel-item-${index}-${item.backgroundImage.imageUrlKey}`}
              rowIndex={rowIndex}
              rowKey={key}
              isKidsPage={isKidsPage}
              isScrollable={isScrollable}
              isStackable={isStackable}
            />
          );
        })}
      </CarouselRowContainer>
    </div>
  );
};

const WithVisibilityContextCollectionView: React.FunctionComponent<OwnProps> = (
  props: OwnProps
) => {
  const { rowIndex, source } = props;

  const analyticsData = React.useMemo(
    () => ({
      rowLabelText: source.rowTitle?.title,
      rowKey: source.key,
      row: rowIndex,
    }),
    []
  );

  return (
    <VisibilityProvider
      numItems={props.source.items.length}
      analyticsData={analyticsData}
    >
      <CarouselRow {...props} />
    </VisibilityProvider>
  );
};

export default WithVisibilityContextCollectionView;
