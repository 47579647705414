import React, { useMemo } from 'react';
import { Box } from 'domains/web/components';
import { Icon } from 'components/Icon';

const TOTAL_STARS = 5;

export const Rating = ({ rating }) => {
  const stars = useMemo(() => Array(TOTAL_STARS).fill(0), []);
  return (
    <Box display="flex">
      {stars.map((item, index) => (
        <Icon
          key={`star-${index}`}
          size="xxs"
          color="yellow1"
          name={index < Math.floor(rating) ? 'star' : 'star-o'}
        />
      ))}
    </Box>
  );
};
