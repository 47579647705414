export enum SectionType {
  CATEGORIES = 'CATEGORIES',
  HOW_IT_WORKS = 'HOW_IT_WORKS',
  MARKETING_CAROUSEL = 'MARKETING_CAROUSEL',
  MARKETING_SPOTLIGHT = 'MARKETING_SPOTLIGHT',
  HEADER_CATEGORIES = 'HEADER_CATEGORIES',
  REACTION_VIDEOS = 'REACTION_VIDEOS',
  RECENTLY_VIEWED = 'RECENTLY_VIEWED',
  LEADERBOARD = 'LEADERBOARD',
  REVIEWS = 'REVIEWS',
  PRICES = 'PRICES',
  INSPO_CAROUSEL = 'INSPO_CAROUSEL',
  THIS_IS_CAMEO = 'THIS_IS_CAMEO',
  BANNER = 'BANNER',
  SOCIAL_BANNER = 'SOCIAL_BANNER',
  RANKED_ORDERS = 'RANKED_ORDERS',
}
