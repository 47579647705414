import React from 'react';
import analytics from 'analytics';
import { resizeImage } from 'utils/getResizedImageUrl';
import { CarouselItem as CarouselItemType } from 'types/src/api/endpoints/explore/browseRow';
import WithItemVisibility from '../../../CollectionView/VisibilityContext/WithItemVisibility';
import {
  CaptionBelowContainer,
  CaptionOverlayContainer,
  CarouselItemContainer,
  CoverContainerLink,
  CoverPhoto,
  CtaLink,
} from './Styled';

type OwnProps = {
  colIndex: number;
  item: CarouselItemType;
  rowIndex: number;
  rowKey: string;
  isKidsPage?: boolean;
  isScrollable?: boolean;
  isStackable?: boolean;
};

const CarouselItem: React.FunctionComponent<OwnProps> = ({
  colIndex,
  item,
  rowIndex,
  rowKey,
  isKidsPage,
  isScrollable,
  isStackable,
}: OwnProps) => {
  const {
    cta,
    backgroundImage,
    caption,
    captionPosition = 'below',
    captionColor,
  } = item;

  const trackCarouselClick = React.useCallback(() => {
    const data = {
      eventName: 'Navigate',
      eventDtl: {
        eventType: 'Click',
      },
      objectDtl: {
        objectType: 'Module',
        containerType: 'Carousel',
      },
      sourceDtl: {
        sourceType: 'Home',
        sourcePath: cta.text,
      },
      targetDtl: {
        targetType: 'Category-Browse',
        targetPath: cta.text,
        targetKey: 'Tag',
        targetValue: cta.linkKey,
      },
      positionDtl: {
        col: colIndex,
        row: rowIndex,
        rowKey,
        rowLabel: cta.text,
      },
    } as const;

    analytics.trackAction(data);
  }, []);
  const openInNewTab = cta.linkType === 'external';

  return (
    <CarouselItemContainer
      isKidsPage={isKidsPage}
      isStackable={isStackable}
      isScrollable={isScrollable}
    >
      <CoverContainerLink
        {...item.cta}
        linkType={cta.linkType}
        linkKey={cta.linkKey}
        openInNewTab={openInNewTab}
        onClick={trackCarouselClick}
      >
        <CoverPhoto
          loading="lazy"
          data-testid="imagePoster"
          alt={backgroundImage.altText}
          src={resizeImage(450, 250, backgroundImage.imageUrlKey)}
        />
      </CoverContainerLink>
      {Boolean(cta.text) && (
        <CtaLink
          {...item.cta}
          linkType={cta.linkType}
          linkKey={cta.linkKey}
          openInNewTab={openInNewTab}
          onClick={trackCarouselClick}
        >
          <div>{cta.text}</div>
        </CtaLink>
      )}

      {captionPosition === 'overlay' && Boolean(caption) && (
        <CaptionOverlayContainer
          {...item.cta}
          linkType={cta.linkType}
          linkKey={cta.linkKey}
          openInNewTab={openInNewTab}
          onClick={trackCarouselClick}
          captionColor={captionColor}
        >
          {caption}
        </CaptionOverlayContainer>
      )}

      {captionPosition === 'below' && Boolean(caption) && (
        <CaptionBelowContainer
          {...item.cta}
          linkType={cta.linkType}
          linkKey={cta.linkKey}
          openInNewTab={openInNewTab}
          onClick={trackCarouselClick}
          captionColor={captionColor}
        >
          {caption}
        </CaptionBelowContainer>
      )}
    </CarouselItemContainer>
  );
};

const WithVisibilityContextCarouselItem = (props: OwnProps) => {
  return (
    <WithItemVisibility index={props.colIndex}>
      <CarouselItem {...props} />
    </WithItemVisibility>
  );
};

export default React.memo(WithVisibilityContextCarouselItem);
