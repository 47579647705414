import styled from 'styled-components';
import { color, fonts, media, sizing } from 'design-system/style';
import BrowseLink from '../../../BrowseLink';
import { KIDS_LARGE_DESKTOP_WIDTH } from '../constants';

const KIDS_LARGE_DESKTOP_BREAKPOINT = `${KIDS_LARGE_DESKTOP_WIDTH}px`;

export const CarouselItemContainer = styled.div<{
  isKidsPage: boolean;
  isStackable: boolean;
  isScrollable: boolean;
}>`
  display: flex;
  flex-direction: column;
  min-width: ${sizing(308)};
  position: relative;
  width: 100%;

  @media (min-width: 430px) {
    width: unset;

    ${({ isKidsPage }) => !isKidsPage && `min-width: ${sizing(310)}`};
  }

  @media (min-width: ${media.queries.TABLET}) {
    ${({ isKidsPage }) => !isKidsPage && `min-width: ${sizing(308)}`}
  }

  @media (min-width: ${KIDS_LARGE_DESKTOP_BREAKPOINT}) {
    ${({ isKidsPage }) => isKidsPage && `min-width: unset`};
  }
`;

export const CoverContainerLink = styled(BrowseLink)`
  width: 100%;
`;

export const CoverPhoto = styled.img`
  width: 100%;
  border-radius: ${sizing(8)};
`;

export const CaptionOverlayContainer = styled(BrowseLink)<{
  captionColor: string;
}>`
  position: absolute;
  padding-top: 5%;
  padding-left: ${sizing(18)};
  padding-right: ${sizing(18)};
  width: 100%;

  background-size: cover;
  z-index: 1;

  color: white;
  ${({ captionColor }) => captionColor && `color: ${captionColor};`}
  font-family: ${fonts.boldText[800]};

  @media (min-width: ${media.queries.TABLET}) {
    font-family: ${fonts.boldText[600]};
  }

  @media (min-width: ${media.queries.LARGE_DESKTOP}) {
    font-family: ${fonts.boldText[1000]};
  }
`;

export const CaptionBelowContainer = styled(BrowseLink)`
  color: ${color.solids.WHITE};
  font-family: ${fonts.regularText[300]};
  padding-top: ${sizing(8)};
  margin-bottom: ${sizing(10)};

  &:focus,
  &:hover,
  &:visited {
    text-decoration: none;
    color: ${color.solids.WHITE};
  }

  @media (min-width: ${media.queries.TABLET}) {
    margin-bottom: ${sizing(15)};
    font-family: ${fonts.regularText[400]};
  }
`;

export const CtaLink = styled(BrowseLink)`
  color: ${color.solids.WHITE};
  font-family: ${fonts.boldText[600]};
  margin-right: ${sizing(2)};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  &:focus,
  &:hover,
  &:visited {
    color: ${color.solids.WHITE};
  }

  @media (min-width: ${media.queries.TABLET}) {
    font-family: ${fonts.boldText[700]};
  }
`;
