import styled from 'styled-components';
import { media, sizing } from 'design-system/style';
import { customPropValidator } from 'domains/web/components/utils/customPropValidator';

export const CarouselRowContainer = styled.section.withConfig({
  shouldForwardProp: customPropValidator(['isKidsPage', 'isStackable']),
})<{
  isKidsPage: boolean;
  isStackable: boolean;
  marginBottom?: string;
}>`
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  justify-content: space-between;
  margin-bottom: ${({ marginBottom }) => marginBottom || '25px'};
  scroll-snap-type: x proximity;
  scroll-margin: ${({ theme }) => theme.space.md};
  scroll-behavior: smooth;

  gap: ${sizing(16)};

  ${({ isStackable }) =>
    isStackable && `@media (max-width: 420px) {flex-direction: column }`}

  @media (max-width: ${media.queries.TABLET}) {
    margin-bottom: ${({ marginBottom }) => marginBottom || '30px'};
    justify-content: flex-start;

    ${({ isKidsPage }) => isKidsPage && `gap: ${sizing(8)}`}
  }

  &::-webkit-scrollbar {
    width: 0 !important;
    height: 0 !important;
  }
`;
